/* 

.content {
  color: #fff;
  padding: 0 3em;
  box-sizing: border-box;
  height: 100vh;
  max-width: 80%;
  margin: 0 auto;
} */

.about-row {
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  gap: 5em; /* Space between image and text */
  padding: 5em 10em; /* Consistent padding */
  max-width: 70%; /* Ensure the content doesn't stretch too wide */
  margin: 0 auto; /* Center the entire section on the page */
}

.about-row img {
  flex: 1; /* Image takes up one part */
  width: 35em; /* Maintain minimum width */
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.25) 0px 10px 10px; /* Combined box-shadow */
  display: block; /* Ensure the image is a block element */
  margin: 0 auto; /* Center the image inside its flex item */
}

.about-text {
  flex: 2; /* Text takes up two parts */
  max-width: 80%; /* Constrain text width */
}

.about-text small {
  display: block;
  margin-bottom: 0.5em;
  font-size: 2em;
}

.about-text h1 {
  margin-bottom: 1em;
}

.about-text p {
  color: #ccb;
  max-width: 100%;
  font-size: 1rem;
  line-height: 1.7; /* Ensure readability */
  text-align: justify;
}

.highlight {
  color: #ff6347;
}

@media screen and (max-width: 1200px) {
  .about-row {
    padding: 4em 0 !important;
  }

  .about-text {
    max-width: 100%; /* Constrain text width */
  }
}



@media screen and (max-width: 900px) {
  /* .about-row {
    padding-top: 5rem;} */

}

@media screen and (max-width: 800px) {
  .about-row {
    max-width: 80%;}

}

@media screen and (max-width: 630px) {
  .about-row img {
    margin: 0em;
    width: 25em !important;
  }
  .posts {
    padding: 0;
  }
}
