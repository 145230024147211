.posts {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 3em 3em;
  height: auto;
  margin: 0em 0em 10em 0em;
}

.post {
  display: flex;
  flex-direction: column; /* Stack children vertically */
  gap: 10em; /* Adjust this value to control the vertical gap */
}

.album-gallery {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  align-items: flex-start;
  padding: 3rem;
}

.post.album {
  position: relative;
  width: 300px;
  height: 200px;
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.5s ease, box-shadow 0.3s ease;
  view-timeline-name: --item-timeline;
  animation: slide-fade-in 1s both; /* Adjust the duration here */
  animation-timeline: --item-timeline;
  animation-range: contain 0% contain 70%;
}


.slide-in {
  opacity: 0;
  transform: translateY(25vh);
  transition: opacity 1s ease-out, transform 2s ease-out;
}

.slide-in-visible {
  opacity: 1;
  transform: translateY(0);
}


.post.album img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.8s ease;
}

.post.album:hover img {
  transform: scale(1.1);
}

.post.album:hover .album-info {
  opacity: 1;
}

.post.album .album-info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  text-align: center;
  padding: 5px;
  opacity: 0;
  transition: opacity 0.3s ease;
  margin: 0; /* Ensure no margin */
}

.album-info h3,
.album-info p {
  margin: 6px;
}

.row div {
  margin: 1em 1em; /* Adjust this value to control the vertical gap */
  max-width: 300px; /* Increase the maximum width of the text container */
}

.row p {
  font-size: 1rem; /* Increase the font size if needed */
  line-height: 1.6; /* Increase line height for better readability */
  max-width: 100%; /* Ensure the paragraph takes the full width of its container */
}

.row img {
  width: 22em;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 10px 10px;
}


@media screen and (max-width: 1220px) {
.post.album {
  width: 198px;
  height: 132px;
}

.album-gallery{
  padding: 0;
}
.posts{
  padding: 4px 0px;
}
}

@media screen and (max-width: 700px) {
  .post.album {
    width:  100%;
    height: 200px;
    width: 300px;
    padding: 0;
  }
}

@media screen and (max-width: 675px) {
  .post.album {
    width:  100%;
    height: 150px;
    width: 250px;
    padding: 0;
  }
}

@media screen and (max-width: 575px) {
  .post.album {
    width:  100%;
    height: 120px;
    width: 200px;
    padding: 0;
  }
}

@media screen and (max-width: 500px) {
  .post.album {
    width:  100%;
    height: 100px;
    width: 150px;
    padding: 0;
  }
}

@media screen and (max-width: 380px) {
  .post.album {
    width:  100%;
    height: 80px;
    width: 120px;
    padding: 0;
  }
}