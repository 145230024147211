/* PrivatePage.css */

.private-page-container {
    font-family: "Carme", sans-serif;
    color: #1a1a1a;
    background-color: #f5f5f5;
    padding: 2em;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 2em auto;
    position: relative; /* So the X button stays within the container */
  }
  
  .private-page-header {
    font-size: 2em;
    font-weight: bold;
    color: #ff6347;
    margin-bottom: 1em;
  }
  
  .email-list {
    list-style-type: none;
    padding: 0;
  }
  
  .email-list li {
    background-color: white;
    padding: 1em;
    margin-bottom: 0.5em;
    border-radius: 5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .email-list li:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .email-list li span {
    font-weight: bold;
    color: #333;
  }
  
  .email-list li .timestamp {
    font-size: 0.9em;
    color: #888;
    display: block;
    margin-top: 0.5em;
  }
  
/* Close button positioned on the right side of the screen */
.close-button-X {
    position: fixed;
    top: 10px;
    right: 10px; /* Distance from the right side of the screen */
    background: none;
    border: none;
    font-size: 1.5em;
    color: #ff6347;
    cursor: pointer;
    transition: color 0.3s ease;
    z-index: 1000; /* Ensure it's on top of other elements */
  }
  
  .close-button:hover {
    color: #e5533d;
  }