/* ----------------- Header and Navbar Styles ----------------- */
header {
  height: 100vh;
  background: linear-gradient(
    0deg,
    rgba(26, 26, 26, 1) 0%,
    rgba(26, 26, 26, 0.8) 40%
  ),
  url(../images/dan-otis-OYFHT4X5isg-unsplash.jpg);
  background-size: cover;
  background-position: center;
}

header h1 {
  font-size: 2.5rem;
  color: #ccb;
}

nav {
  z-index: 99;
  position: relative;
  background-color: transparent;
}

nav .menu {
  display: flex;
  justify-content: space-between;
  max-width: 70%;
  padding: 2em 0;
  margin: 0 auto;
}

nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 2em;
}

nav ul li {
  display: inline-block;
}

nav ul a {
  font-size: 0.8em;
  text-transform: uppercase;
  color: #ccb;
  text-decoration: none;
  transition: 0.3s;
}

nav ul a:hover {
  color: #e5533d;
}

/* Social Media Icons */
.social-media {
  display: flex;
  gap: 1em;
}

.social-media a {
  font-size: 1em;
  color: #ccb;
  text-decoration: none;
  transition: 0.3s;
}

.social-media a:hover {
  color: #e57373;
}

/* Menu Button */
.menu-btn {
  display: none;
  position: absolute;
  top: 1.5rem;
  right: 1rem;
  color: #fff;
  width: 2em;
  height: 2.8em;
  cursor: pointer;
  transition: 0.4s;
  font-size: 1.5em;
  z-index: 1000; /* Ensure it's above the menu overlay */
}

/* Hide the menu by default in mobile view */
nav .menu {
  flex-direction: row;
  transition: all 0.5s ease;
}

/* When the nav is toggled open */
nav.nav-toggle .menu {
  position: fixed; /* Change to fixed for full viewport coverage */
  top: 0;
  left: 0;
  width: 100%; /* Ensure full width */
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 999; /* Below the menu button */
}

nav.nav-toggle ul {
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: 100%; /* Full width for menu items */
  text-align: center; /* Center text */
}

nav.nav-toggle ul a {
  font-size: 1.2em;
  color: #fff;
  display: block; /* Make links block-level for larger click area */
  padding: 0.5em 0; /* Add some vertical padding */
}

/* Mobile Styles for the Menu */
@media screen and (max-width: 1300px) {
  header {
    height: auto;
  }
  
  nav .menu {
    gap: 8em;
    width: 80%;
  }
}

@media screen and (max-width: 900px) {
  nav .menu {
    max-width: 100%; /* Ensure full width on mobile */
    padding: 0; /* Remove padding */
  }

  nav ul {
    display: none; /* Hide menu items by default */
  }

  nav .menu-btn {
    display: block; /* Show the menu button */
  }

  /* Hide social media icons when the menu is open on small screens */
  .social-media {
    display: none !important;
  }

  /* Ensure the menu takes full width when open */
  nav.nav-toggle .menu {
    width: 100vw; /* Use viewport width */
    max-width: none; /* Override any max-width */
    margin: 0; /* Remove any margin */
    padding: 2em 0; /* Add some vertical padding */
  }
}