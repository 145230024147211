.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Dark overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it's on top of everything */
}

.modal-content {
  background-color: rgba(26, 26, 26, 0.9); /* Increased opacity for better readability */
  padding: 2em;
  border-radius: 10px;
  max-width: 500px;
  width: 90%; /* Adjusted for responsiveness */
  text-align: center;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
}

/* Rest of your CSS remains the same */
.close-btn {
  margin-top: 1em;
  background-color: #ff6347;
  color: white;
  padding: 0.5em 1em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
}

.close-btn:hover {
  background-color: #e5533d;
}

  
  /* Improved email input styling */
  .email-input {
    width: 80%;
    padding: 0.8em;
    border-radius: 25px;
    border: 2px solid #ff6347; /* Add a colored border */
    outline: none;
    font-size: 1em;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
    background-color: #f9f9f9;
    color: #333;
  }
  
  /* Focus effect on the input */
  .email-input:focus {
    border-color: #e5533d;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15); /* Slight shadow increase on focus */
    background-color: #fff; /* Slightly brighter background on focus */
  }
  
  /* Improved submit button styling */
  .submit-btn {
    background-color: #ff6347;
    color: white;
    padding: 0.8em 2em;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    font-size: 1.2em;
    margin-top: 1em;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .submit-btn:hover {
    background-color: #e5533d;
    transform: translateY(-2px); /* Slight lift on hover */
  }
  
  .submit-btn:active {
    background-color: #cc4832;
    transform: translateY(1px); /* Slight press effect */
  }

  .button-group {
    display: flex;
    justify-content: space-between; /* Ensures the buttons are spaced to the sides */
    margin-top: 1.5em;
  }
  
  .button-group button {
    flex: 1;
    margin: 0 10px;
    padding: 0.7em 1.5em;
    border-radius: 25px;
    transition: all 0.3s ease;
  }

  #modal-root {
    position: relative;
    z-index: 9999;
  }