/* Music Player Section */
.music-player-section {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1a1a1a;
    color: #ffffff;
    padding-bottom: 15em;
    text-align: center;
}

/* Music Player Container */
.music-player-container {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    background-color: #222;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.music-player-container h1 {
    margin-bottom: 15px;
    font-size: 1.5rem;
    font-weight: 400;
}

#audio-player {
    width: 100%;
    margin-bottom: 15px;
    background-color: #333;
    border-radius: 5px;
    outline: none;
}

/* Playlist Container */
.playlist-container {
    text-align: left;
}

.playlist-container ul {
    list-style-type: none;
    padding: 0;
}

.playlist-item {
    padding: 8px;
    margin-bottom: 5px;
    background-color: #333;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s, transform 0.2s;
}

.playlist-item:hover {
    background-color: #444;
    transform: scale(1.02);
}

.playlist-item.active {
    background-color: #009688;
}

/* Player Bar */
.player-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #1a1a1a;
    padding: 8px 15px; /* Reduce padding to make the bar smaller */
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    border-top: 1px solid #333;
    height: 50px; /* Reduce the height of the player bar */
}


.player-bar-content {
    color: #ccb;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


/* Track Info */
.track-info h2 {
    color: #ccb;
    font-size: 0.9rem; /* Reduce font size for more space */
    font-weight: 400;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; /* Ensure text does not overflow */
}

/* Controls */
.controls {
    display: flex;
    gap: 1rem;
}

.controls button {
    background: none;
    border: none;
    color: #ccb;
    font-size: 1.2rem;
    cursor: pointer;
    transition: transform 0.2s ease, color 0.3s ease;
}

.controls button:hover {
    transform: scale(1.2);
    color: #e5533d;
}

/* Close Button */
.close-button {
    background: none;
    border: none;
    color: #ccb;
    font-size: 1.5rem;
    cursor: pointer;
    transition: transform 0.2s ease, color 0.3s ease;
}

.close-button:hover {
    transform: scale(1.2);
    color: #e57373;
}

/* Progress Bar */
.progress-bar-container {
    position: relative;
    width: 100%;
    height: 8px;
    background-color: #444;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 10px;
}

.progress-bar {
    height: 100%;
    background-color: #e5533d;
    border-radius: 5px;
}