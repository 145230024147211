footer {
    background: #161618;
    color: #ffffff;
    padding: 2em 0;
    font-family: 'Helvetica Neue', sans-serif;
    font-size: 14px;
    border-top: 1px solid #444;
}

.footer-top {
    display: flex;
    justify-content: center;
    padding-bottom: 1em;
}

.footer-bottom {
    padding: 1em 2em;
    border-top: 1px solid #444;
}

.footer-bottom div {
    flex: 1;
    text-align: center;
}

.footer-bottom ul {
    list-style-type: none;
    padding: 2em 0 1em 0;
    margin: 0;
    display: flex;
    gap: 2em;
    justify-content: center;
}

.footer-bottom ul li {
    display: inline;
}

.footer-bottom a {
    color: #ffffff;
    text-decoration: none;
    transition: color 0.3s ease;
}

.footer-bottom a:hover {
    color: #ff7f50;
}

.footer-bottom p {
    margin-top: 1em;
    text-align: center;
    font-size: 12px;
    color: #888;
}

.footer-bottom .year {
    margin-left: 0.5em;
}