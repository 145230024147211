.plug-in {
  position: relative;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2em;
  background-color: rgba(240, 240, 240, 0.9); /* Light gray with some transparency */
  overflow: hidden; /* Ensure the background stays within this section */
}

.plugin-container {
  position: relative;
  z-index: 1; /* Ensure content is above the background */
  display: flex;
  justify-content: center;
  gap: 2em;
  max-width: 1400px;
  width: 100%;
}

.container-plug-in {
  background-color: rgba(26, 26, 26, 0.9); /* Slightly transparent dark background */
  border-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  width: 700px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  opacity: 0;
  transform: translateY(200px);
}

.container-plug-in.visible {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.container-plug-in:hover {
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
  transform: translateY(-5px);
}

.plug-in-img {
  width: 100%;
  height: 420px;
  overflow: hidden;
  position: relative;
}

.plug-in-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  transition: filter 0.3s ease;
}

.container-plug-in:hover .plug-in-img img {
  filter: brightness(1.3);
}

.description-container {
  padding: 1.5em;
  text-align: center;
}

.description-container h2 {
  margin-bottom: 0.5em;
  color: #ffffff; /* White text for better contrast */
  font-size: 1.6em;
  transition: color 0.3s ease;
}

.container-plug-in:hover .description-container h2 {
  color: #ff7f50;
}

.description-container p {
  font-size: 0.9em;
  line-height: 1.6;
  color: #e0e0e0; /* Light gray text for better contrast */
  margin-bottom: 1em;
}

.purchase-container {
  padding: 0 1.5em 1.5em;
  text-align: center;
}

.purchase-container button {
  font-family: "Carme", sans-serif;
  font-size: 1em;
  font-weight: bold;
  border: none;
  border-radius: 50px;
  padding: 0.8em 2em;
  width: 100%;
  background-color: #ff6347;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.purchase-container button::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 5px;
  background: rgba(255, 255, 255, 0.5);
  opacity: 0;
  border-radius: 100%;
  transform: scale(1, 1) translate(-50%);
  transform-origin: 50% 50%;
}

.purchase-container button:hover::after {
  animation: ripple 1s ease-out;
}

@keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 0.5;
  }
  100% {
    transform: scale(20, 20);
    opacity: 0;
  }
}

.purchase-container button:hover {
  background-color: #e5533d;
}




@media screen and (max-width: 1440px) {
  .plugin-container {
    flex-direction: column;
    align-items: center;
    padding-top: 10em;
  }
  
  .container-plug-in {
    width: 90%;
    max-width: 700px;
    margin-bottom: 2em;
  }
}

@media screen and (max-width: 768px) {
  .plug-in {
    width: 100%;
    padding: 0;
    margin: 0;
  }
}

@media screen and (max-width: 600px) {
  .plug-in-img {
    height: 300px;
  }
  
  .plug-in-img img {
    height: 300px;
    object-fit: contain;
  }
}

@media screen and (max-width: 450px) {
  .plug-in-img {
    height: 250px;
  }
  
  .plug-in-img img {
    height: 300px;
    object-fit: contain;
  }
}